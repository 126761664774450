export default class Notifications {
  attach() {
    if (Tefter.notifications_enabled) {
      this.CHECK_INTERVAL = 5 * 60000; // 5 minutes

      if (Tefter.user_signed_in) {
        this.checkUnread();
        this.timer = window.setInterval(this.checkUnread.bind(this), this.CHECK_INTERVAL);
      }
    }
  }

  checkUnread() {
    const params = { organization_id: window.Tefter.organizationId };

    $.getJSON('/notifications/check_unread', params, function(response) {
      const { unread: unread } = response;

      if (unread) {
        $('.header-settings .notifications').addClass('unread-notifications');
        $('.sidebar-user .notifications').addClass('unread-notifications');
      }
    })
  }
}
