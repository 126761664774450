export default class Integrations {
  attach() {
    this.embedPlayers();
  }

  embedPlayers() {
    $('.card .screenshot[data-integration=youtube]').each(function(_i, el) {
      this.embedPlayer($(el), 'youtube');
    }.bind(this));

    $('.card .screenshot[data-integration=soundcloud]').each(function(_i, el) {
      this.embedPlayer($(el), 'soundcloud');
    }.bind(this));

    $('.card .screenshot[data-integration=mixcloud]').each(function(_i, el) {
      this.embedPlayer($(el), 'mixcloud');
    }.bind(this));
  }

  embedPlayer($element, type) {
    $element.html(this[`${type}Embed`]($element.data(`${type}Id`),
                                        $element.width(),
                                        $element.height()));
    $element.addClass('embedded-player');
  }

  youtubeEmbed(id, width, height) {
    return `<iframe width="${width}" height="${height}" ` +
      `src="//www.youtube.com/embed/${id}" frameborder="0" `  +
      `allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
  }

  soundcloudEmbed(id, width, height) {
    const base_url = `https://w.soundcloud.com/player/?` +
          `url=https%3A//api.soundcloud.com/tracks/`;
    const opts = `&color=%23ffffff&auto_play=false&hide_related=true` +
          `&show_comments=false&show_user=true&show_reposts=false` +
          `&show_teaser=true&visual=true`;
    const source = `${base_url}${id}${opts}`;

    return `<iframe width="${width}" height="${height}" ` +
      `scrolling="no" frameborder="no" allow="autoplay" ` +
      `src="${source}"></iframe>`;
  }

  mixcloudEmbed(id, width, height) {
    const base_url = `https://www.mixcloud.com/widget/iframe/?`;
    const encodedId = encodeURIComponent(`/${id}/`);
    const source = `${base_url}feed=${encodedId}`;

    return `<iframe width="${width}" height="${height}" ` +
      `scrolling="no" frameborder="no" allow="autoplay" ` +
      `src="${source}"></iframe>`;
  }
}
