import MicroModal from 'micromodal';
import Notifications from './notifications.js';
import Integrations from './integrations.js';
import QuickBookmark from 'controllers/quick_bookmark_controller.js';
import Mousetrap from 'mousetrap';
import CookieConsent from 'cookieconsent';
import * as Sentry from '@sentry/browser';

MicroModal.init({
  openTrigger: 'data-custom-open',
  disableScroll: true,
  awaitCloseAnimation: true
});

function setMousetrap() {
  Mousetrap.bind('/', function(e) {
    $('.autocomplete input').focus();

    return false;
  });

  Mousetrap.bind(['command+b', 'ctrl+b'], function(e) {
    (new QuickBookmark).reset();
    $('.new-bookmark').click();
    $('.quick-bookmark-form').find('input[name=url]').focus();

    return false;
  });
}

function closeAllModals() {
  $('.modal__overlay').on('click', function(event) {
    $('.micromodal-slide').each(function(element) {
      window.Tefter.MicroModal.close(element['id']);
    })

    // Reset sidebar css opacity
    $('.sidebar').css('opacity', 1);
  });
}

function loadMicroModal() {
  window.Tefter.MicroModal = MicroModal;
  let bodyClass = '.dashboard';

  $(document).on('click', '.js-modal-trigger', function() {
    let modalId = this.getAttribute('data-modal-id');

    MicroModal.show(modalId, {
      debugMode: false,
      disableScroll: false,
      onShow: function (modal) { document.querySelector(bodyClass).classList.add(modal.id) },
      onClose: function (modal) { document.querySelector(bodyClass).classList.remove(modal.id);
                                  $('.sidebar').css('opacity', 1.0)},
      closeTrigger: 'data-custom-close',
      awaitCloseAnimation: true
    })

    if (modalId != 'modal-quick-bookmark') {
      $('.sidebar').css('opacity', 0.35);
    }
  });

  $(document).on('click', '.js-modal-close-trigger', function() {
    let modalId = this.getAttribute('data-modal-id');

    event.preventDefault();

    MicroModal.close(modalId);

    // Reset sidebar opacity
    $('.sidebar').css('opacity', 1);
  });
}

function initHeadway() {
  if (typeof Headway === 'undefined') {
    return;
  }

  try {
    Headway.init(headwayConfig);
  } catch(error) { }
}

function displayCookieConsent() {
  window.cookieconsent.initialise({
    enabled: !localStorage.getItem('tefter-cookie-got-it'),
    cookie: {
      expiryDays: 365,
      domain: ".tefter.io",
      secure: true,
      name: "cookieconsent"
    },

    palette: {
      popup: {
        background: "#eaf7f7",
        text: "#5c7291"
      },

      button: {
        background: "#56cbdb",
        text: "#ffffff"
      }
    },

    position: 'bottom-left',

    content: {
      header: 'Cookies used on the website',
      message: 'Tefter uses cookies to ensure you get the best experience.',
      dismiss: 'Got it!',
      allow: 'Allow cookies',
      deny: 'Decline',
      link: 'Learn more',
      href: 'https://tefter.io/privacy-policy',
      close: '&#x274c;',
      policy: 'Cookie Policy',
      target: '_blank',
    },

    onStatusChange: function(status, chosenBefore) {
      if (status == 'dismiss') {
        localStorage.setItem('tefter-cookie-got-it', 'true');
      }
    },
  });
}

setMousetrap();

loadMicroModal()

closeAllModals();

(new Notifications).attach();

(new Integrations).attach();

initHeadway();

displayCookieConsent();

if (window.Tefter.environment == 'production') {
  Sentry.init({ dsn: 'https://d2da3c61c76043df930d66235d008f5b@sentry.io/1208965' });
}
