import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'message', 'button'];

  reset() {
    let $modal = $("#modal-quick-bookmark");

    let title = 'Add bookmark';

    if (window.Tefter.orgName) {
      title = `${title} to ${window.Tefter.orgName}`;
    }

    $("#modal-quick-bookmark-title").text(title);

    $modal.find('input[type=text]').show().val('');
    $modal.find('input[type=text]').val('');

    setTimeout(function() { $modal.find('input[type=text]').focus(); }, 100);

    $modal.find('.message').text('');
    $modal.find('.create').show().val('Save')
  }

  handleResponse(event) {
    let [data, status, xhr] = event.detail;
    let message = '';

    if (data.status === 422) {
      if (data.responseText.match('Your plan supports')) {
        message = JSON.parse(data.responseText).errors.title;
      }
      else {
        message = `Address is invalid. Please provide a well
formatted address like https://www.example.com`
      }

      this.messageTarget.innerHTML = message;
    }

    if (data.status === 200) {
      let response = JSON.parse(data.response);

      if (response.resource.existing == true) {
        $(this.buttonTarget).hide();

        let url = '/bookmarks/' + response.resource.id + '/edit';

        if (window.Tefter.organizationSlug && window.Tefter.organizationSlug.length > 0) {
          url = `/orgs/${window.Tefter.organizationSlug}${url}`;
        }

        message = `Bookmark already exists. Click <a href="${url}">here</a> to edit.`;

        this.messageTarget.innerHTML = message;
      }
    }

    if (data.status === 201) {
      let response = JSON.parse(data.response);
      let ctrl = this;

      this.fetchMetadata(this.inputTarget.value).then(function(metadata) {
        let modalId = ctrl.element.id.replace('-content', '');

        $(ctrl.inputTarget).hide();
        $(ctrl.buttonTarget).hide();
        $("#modal-quick-bookmark-title").text('Bookmark added!');

        let url = '/bookmarks/' + response.resource.id + '/edit';
        let refreshUrl = '/bookmarks';

        if (window.Tefter.organizationSlug && window.Tefter.organizationSlug.length > 0) {
          url = `/orgs/${window.Tefter.organizationSlug}${url}`;
          refreshUrl = `/orgs/${window.Tefter.organizationSlug}${refreshUrl}`;
        }

        if (metadata.title) {
          message = `
            “${metadata.title}”
            <br/>
            <a class="btn" href=${url}>Edit</a>
            `;
        } else {
          message = `<a class="btn" href=${url}>Edit</a>`;
        }

        window.setTimeout(window.Tefter.MicroModal.close, 2 * 1000, modalId);
        window.setTimeout(Turbo.visit, 1000, refreshUrl, { action: 'replace' });

        ctrl.messageTarget.innerHTML = message;
      });
    }

    this.buttonTarget.value = 'Save';
  }

  fetchMetadata(url) {
    return $.post('/bookmarks/infer_metadata', { url: url, timeout: 1000 });
  }

  submit(event) {
    this.buttonTarget.value = 'Saving..';
  }
}
